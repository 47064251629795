body,html{
    background: #3b3b3b;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

}

.login-container{
    background-color: transparent;
    min-height: 100px;
    width: 600px;
    color:aqua;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%) ;
    -webkit-transform:translate(-50%,-50%) ;
    -moz-transform:translate(-50%,-50%) ;
    -ms-transform:translate(-50%,-50%) ;
    -o-transform:translate(-50%,-50%) ;
}